<div class="panel panel-warning" style="border:0px solid red;margin:0!important; width: 75vw !important; height: 90vh !important; overflow: hidden">
  <div class="panel-heading" style="padding: 5px; min-height: 35px;">
    <span class="h4">Xem tệp đính kèm</span>
    <button mat-dialog-close class="btn btn-default btn-xs pull-right" style="min-height: 24px;margin-left:10px">
      <i class="fa fa-times"></i>&nbsp;&nbsp;Hủy</button>
    <button class="btn btn-success btn-xs pull-right" (click)="DownloadFile()" style="min-height: 24px;margin-left:15px">
      <i class="far fa-download"></i>&nbsp;&nbsp;Tải về</button>
  </div>
  <div class="modal-body no-padding" style="width:100%;height:96%">
    <div *ngIf="isCanPreview && !IsPDF && !IsIMG" style="margin-top: 10px !important;width:100% !important;height:100% !important">
      <iframe id="viewfile" class="no-border" style="width:100%;height:100%;text-align:center" 
        src=""></iframe>
    </div>
    
    <div *ngIf="isCanPreview && IsPDF && !IsIMG" style="margin-top: 20px !important;width:100% !important;height:78vh !important">
      <ng2-pdfjs-viewer #pdfViewer></ng2-pdfjs-viewer>
    </div>
    
    <div id="idOnHTML" *ngIf="isCanPreview && IsIMG" style="width:100% !important;height:76vh !important">
      <app-image-viewer [images]="listImg"
                        [idContainer]="'idOnHTML'"
                        [loadOnInit]="false"
                        [showPDFOnlyOption]="false" 
                        [download]="false"></app-image-viewer>
    </div>
    <div *ngIf="!isCanPreview">
      <h4 class="not-support">Tệp tin không hỗ trợ xem trước, vui lòng tải xuống để xem đầy đủ nội dung</h4>
    </div>
  </div>
</div>
