import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { RegisterComponent } from './components/register/register.component';

const routes: Routes = [
  {
    path: 'dang-nhap',
    component: LoginComponent
  },
  {
    path: 'quen-mat-khau',
    component: ForgetPasswordComponent
  },
  // {
  //   path: 'dang-ky/:code',
  //   component: RegisterComponent
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class LoginsRoutingModule { }
