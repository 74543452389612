import { NgModule, Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'
import { DanhThiepComponent } from './modules/logins/components/danh-thiep/danh-thiep.component'

const routes: Routes = [
	{ path: ':id', component: DanhThiepComponent},
]

@NgModule({
	imports: [CommonModule, RouterModule.forRoot(routes)],
	declarations: [],
	exports: [RouterModule],
})
export class AppRoutingModule {}
