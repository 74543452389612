import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // <-- NgModel lives here
import { BrowserXhr, HttpModule } from '@angular/http'
import { MatDialogModule } from '@angular/material'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { ToastrModule } from 'ngx-toastr'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CustomBrowserXhr } from './commons/CustomBrowserXhr'
import { ConfirmDialogComponent } from './directives/confirm-dialog/confirm-dialog.component'
import { SvFocusDirective } from './directives/sv-focus.directive'
import { ViewFileDialogComponent } from './directives/viewfile-dialog/viewfile-dialog.component'
import { CustomHttpInterceptor } from './http-interceptor/customHttpInterceptor'
import { ForbidenPageModule } from './modules/forbiden-page/forbiden-page.module'
import { LoginsModule } from './modules/logins/logins.module'
import { TreeModule } from 'primeng/tree'
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'
import { ImageViewerModule } from 'ng2-image-viewer'
import { CookieService } from 'ngx-cookie-service'
import { DxReportViewerModule } from 'devexpress-reporting-angular' 
// import { getFirestore, provideFirestore } from '@angular/fire/firestore';
// import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { FileViewDialogComponent } from './directives/fileview-dialog/fileview-dialog.component'
import { OfficeViewDialogComponent } from './directives/officeview-dialog/officeview-dialog.component'
import { DanhThiepComponent } from './modules/logins/components/danh-thiep/danh-thiep.component'
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

@NgModule({
	declarations: [AppComponent, SvFocusDirective, ConfirmDialogComponent, ViewFileDialogComponent,FileViewDialogComponent,OfficeViewDialogComponent, DanhThiepComponent],
	entryComponents: [ConfirmDialogComponent, ViewFileDialogComponent,FileViewDialogComponent,OfficeViewDialogComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
		HttpClientModule,
		HttpModule,
		BrowserAnimationsModule,
		MatSnackBarModule,
		LoginsModule,
		ForbidenPageModule,
		AppRoutingModule,
		RouterModule,
		ToastrModule.forRoot({
			timeOut: 2000,
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
		}),
		MatDialogModule,
		BsDatepickerModule.forRoot(),
		StoreDevtoolsModule.instrument({
			maxAge: 25, //  Retains last 25 states
		}),
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		PdfJsViewerModule,
		ImageViewerModule,
		DxReportViewerModule,
		TreeModule,

		AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
		AngularFirestoreModule ,
		NgxQRCodeModule,


		// provideFirebaseApp(() => initializeApp(environment.firebase)),
    	// provideFirestore(() => getFirestore()),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CustomHttpInterceptor,
			multi: true,
		},
		{ provide: BrowserXhr, useClass: CustomBrowserXhr },
		CookieService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
