// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	isContentLoading: false,
	olderbacklink: '',
	ga: 'UA-184812651-1',
	useEmulators: true,

	firebase: {
		apiKey: 'AIzaSyAxcCjXztaBDnB3EevWjznUt7fem-JA8yc',
		authDomain: 'pccc-362508.firebaseapp.com',
		projectId: 'pccc-362508',
		storageBucket: 'pccc-362508.appspot.com',
		messagingSenderId: '161734839764',
		appId: '1:161734839764:web:57715c897c452212155a41',
		measurementId: 'G-Z94B7RJT07',
	},
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
