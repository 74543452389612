import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'
import { Inject } from '@angular/core'
import { UploadFileService } from '../../services/uploadfiles.service'
import { saveAs as importedSaveAs } from 'file-saver'
import { ToastrService } from 'ngx-toastr'
import { AppSettings } from '../../constants/app-setting'
import { HttpClient } from '@angular/common/http'
declare var $: any

@Component({
	selector: 'app-viewfile-dialog',
	templateUrl: './viewfile-dialog.component.html',
	styleUrls: ['./viewfile-dialog.component.css'],
})
export class ViewFileDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private filesService: UploadFileService,
		private toastr: ToastrService,
		private http: HttpClient,
		private cdRef: ChangeDetectorRef
	) {}
	downloadId: number = 0
	filename: string = ''
	IsPDF: boolean = false
	IsIMG: boolean = false
	IsShowCreateVersion = false
	files: any
	typeFile: any
	isCanPreview = true
	@ViewChild('pdfViewer', { static: false }) pdfViewer
	listImg: any

	ngOnInit() {
		this.isCanPreview = true
		this.typeFile = this.data.type
		if (this.data.link != null) {
			this.IsPDF = false
			this.IsIMG = false
			this.cdRef.detectChanges()
			// Check nếu là file ảnh thì mở trực tiếp
			// let listsplit = this.data.name;
			// listsplit = listsplit.split('.');
			//listsplit[listsplit.length - 1].toLowerCase();
			let extenfile = this.data.extfile
			if (
				extenfile == 'png' ||
				extenfile == 'PNG' ||
				extenfile == 'jpg' ||
				extenfile == 'JPG' ||
				extenfile == 'jpeg' ||
				extenfile == 'JPEG' ||
				extenfile == 'gif' ||
				extenfile == 'GIF'
			) {
				this.IsIMG = true
				this.LoadViewImage(this.data.link, this.data.name)
			} else if (extenfile == 'pdf' || extenfile == 'PDF') {
				var linkfile = this.data.link
				linkfile = linkfile
				// linkfile = linkfile.replace(AppSettings.API_DOWNLOADFILES, "");
				this.IsPDF = true
				this.IsIMG = false
				this.cdRef.detectChanges()
				this.LoadView(linkfile)
			} else if (
				extenfile == 'doc' ||
				extenfile == 'DOC' ||
				extenfile == 'docx' ||
				extenfile == 'DOCX' ||
				extenfile == 'xls' ||
				extenfile == 'XLS' ||
				extenfile == 'xlsx' ||
				extenfile == 'XLSX'
			) {
				var linkfile = this.data.link
				linkfile = linkfile
				// linkfile = linkfile.replace(AppSettings.API_DOWNLOADFILES, "");
				$('#viewfile').attr('src', AppSettings.VIEW_FILE + btoa(linkfile))
			} else {
				$('#viewfile').attr('src', this.data.link)
				this.isCanPreview = false
			}
			var fileurl = this.data.link
			fileurl = fileurl
			// fileurl = fileurl.replace(AppSettings.API_DOWNLOADFILES, "");
			this.filename = fileurl
		}
	}

	setClassFile(item: any) {
		let listsplit = item.name.split('.')
		let extenfile = listsplit[listsplit.length - 1].toLowerCase()

		if (extenfile == 'pdf' || extenfile == 'PDF') {
			return 'fa-file-pdf-o'
		}
		if (extenfile == 'txt' || extenfile == 'TXT') {
			return 'fa-file-text-o'
		}
		if (extenfile == 'doc' || extenfile == 'docx' || extenfile == 'DOC' || extenfile == 'DOCX') {
			return 'fa-file-word-o'
		}
		if (extenfile == 'xls' || extenfile == 'xlsx' || extenfile == 'XLS' || extenfile == 'XLSX') {
			return 'fa-file-excel-o'
		}
		if (
			extenfile == 'png' ||
			extenfile == 'jpg' ||
			extenfile == 'jpeg' ||
			extenfile == 'gif' ||
			extenfile == 'PNG' ||
			extenfile == 'JPG' ||
			extenfile == 'JPEG' ||
			extenfile == 'GIF'
		) {
			return 'fa-file-image-o'
		} else {
			return 'fa-paperclip'
		}
	}

	DownloadFile() {
		if (this.downloadId != 0) {
			var request = {
				id: this.downloadId,
				type: this.typeFile,
			}
			this.filesService.downloadFilebyId(request).subscribe(
				(response) => {
					var blob = new Blob([response], { type: response.type })
					importedSaveAs(blob, this.filename)
				},
				(error) => {
					this.toastr.error('Không tìm thấy file trên hệ thống')
				}
			)
		} else {
			var req = {
				FilePath: this.data.path,
				FileName: this.data.name,
			}
			this.filesService.downloadFile(req).subscribe(
				(response) => {
					var blob = new Blob([response], { type: response.type })
					var extenfile = this.data.extfile
					if (
						extenfile == 'doc' ||
						extenfile == 'DOC' ||
						extenfile == 'docx' ||
						extenfile == 'DOCX' ||
						extenfile == 'xls' ||
						extenfile == 'XLS' ||
						extenfile == 'xlsx' ||
						extenfile == 'XLSX' ||
						extenfile == 'ppt' ||
						extenfile == 'PPT' ||
						extenfile == 'pptx' ||
						extenfile == 'PPTX'
					) {
						importedSaveAs(blob, this.data.name + '.' + this.data.extfile)
					}
					importedSaveAs(blob, this.data.name)
				},
				(error) => {
					this.toastr.error('Không tìm thấy file trên hệ thống')
				}
			)
		}
	}

	LoadView(link) {
		var request = {
			FilePath: link,
			FileName: this.data.name,
		}
		this.filesService.downloadFile(request).subscribe(
			(response) => {
				var blob = new Blob([response], { type: response.type })
				this.pdfViewer.pdfSrc = blob
				this.pdfViewer.refresh()
			},
			(error) => {
				this.toastr.error('Không tìm thấy file trên hệ thống')
			}
		)
	}

	LoadViewImage(link, name) {
		var request = {
			FilePath: link,
			FileName: name,
		}
		this.filesService.GetFileImage(request).subscribe(
			(response) => {
				var blob = new Blob([response], { type: response.type })
				var blob_url = URL.createObjectURL(blob)
				var reader = new FileReader()
				let that = this
				reader.onload = function () {
					var b64 = reader.result.toString().replace(/^data:.+;base64,/, '')
					that.listImg = []
					that.listImg.push(b64)
				}
				reader.readAsDataURL(blob)
			},
			(error) => {
				this.toastr.error('Không tìm thấy file trên hệ thống')
			}
		)
	}
}
