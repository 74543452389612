<div class="authincation h-100 bg-login">
	<div class="container-fluid h-100">
		<div class="row justify-content-center h-100 bgroud-forgot-password align-items-center">
			<div class="col">
				<div class="authincation-content bg-transparent shadow-none">
					<div class="row no-gutters">
						<!-- <div class="col border-right border-brown remove-boder-right d-none d-md-block">
							<div class="auth-form text-center">
								<a href="/cong-bo"><img class="img-fluid" src="assets/dist/images/img-login.png" alt=""></a>
							</div>
						</div> -->
						<div class="col-sm-12 col-md d-flex justify-content-center align-items-center">
							<div class="auth-form" style="max-width: 450px">
								<h2 class="text-center mb-4 text-uppercase cl-brown">Lấy lại mật khẩu</h2>
								<p class="text-center cl-brown">Nhập email anh/chị đã đăng ký, Chúng tôi sẽ gửi mật khẩu mới qua email đó
									cho anh/chị.</p>
								<form class="login100-form validate-form flex-sb flex-w" [formGroup]="forgetPasswordForm"
									#formDir="ngForm" (ngSubmit)="forgetPassword()">
									<div class="form-group mb-4">
										<input class="form-control px-4 border-brown" type="email" id="email" placeholder="Nhập email..."
											formControlName="email" [(ngModel)]="user.Email" style="font-size: 20px" value="" required
											appAutofocus>
										<div *ngIf="(submitted && f.email.invalid)||(f.email.invalid && (f.email.dirty || f.email.touched))"
											class="alert has-error">
											<div *ngIf="f.email.errors.required">
												<span class="help-block">Trường này không được để trống</span>
											</div>
										</div>
									</div>

									<div class="text-center">
										<button type="button" class="btn btn-forgot btn-block mb-4" (click)="forgetPassword()">Xác nhận</button>
										<button type="button" class="btn rounded-xl bg-white text-dark border" style="font-size: 20px"
											(click)="login()">
											<i class="bi bi-arrow-left"></i> Về trang đăng nhập</button>
										<!-- Modal -->
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="modal-otp">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header d-block">
				<h3 class="modal-title text-center text-uppercase">xác thực mã otp</h3>
				<p class="text-center">Mã xác thực đã được gửi về email của anh/chị:</p>
				<!-- <p class="text-center mb-0 font-weight-bold" style="font-size: 22px">0987***321</p> -->
				<button type="button" class="close" data-dismiss="modal"><span>&times;</span>
				</button>
			</div>
			<div class="modal-body" style="background: #FEF5EC">
				<div class="otp-code">
					<p class="text-center">Nhập mã OTP</p>
					<div class="input-group-otp d-flex justify-content-center">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center">
					</div>
				</div>
			</div>
			<div class="modal-footer justify-content-center">
				<button type="button" class="btn btn-secondary grd-xanhngoc rounded-xl border-0 text-uppercase">Xác thực</button>
			</div>
		</div>
	</div>
</div>