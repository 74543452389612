<div class="panel panel-warning" style="border:0px solid red;margin:0!important; width: 75vw !important; height: 90vh !important; overflow: hidden">
  <div class="panel-heading" style="padding: 5px; min-height: 35px;">
    <span class="h4">Xem tệp đính kèm</span>
    <button mat-dialog-close class="btn btn-default btn-xs pull-right" style="min-height: 24px;margin-left:10px">
      <i class="fa fa-times"></i>&nbsp;&nbsp;Hủy</button>
    <button class="btn btn-success btn-xs pull-right" (click)="DownloadFile()" style="min-height: 24px;margin-left:15px">
      <i class="far fa-download"></i>&nbsp;&nbsp;Tải về</button>
  </div>
  <div class="modal-body no-padding" style="width:100%;height:96%">
    
    <div id="bg_ppt" style="margin-top: 10px !important;width:100% !important;height:100% !important">
      <iframe id="viewppt" class="no-border" style="width:100%;height:100%;text-align:center" 
       src=""></iframe>
    </div>
  </div>
</div>
