<div class="panel panel-default" style="margin:0!important;width: 320px!important; height: auto;">
	<button class="close btn-close-modal" mat-dialog-close><i class="fal fa-times"></i></button>
	<div class="box-confirm">
		<div class="icon"><i class="fal fa-exclamation-circle"></i></div>
		<div class="text">
			<div class="panel-heading"><b class="">Xác nhận hành động!</b></div>
			<span> Anh/chị có chắc chắn thực hiện thao tác này không?</span>
			<div class="modal-footer no-border no-padding mt-3">
				<button md-raised-button mat-dialog-close class="btn btn-light btn-sm mw-100">Hủy</button>
				<button md-raised-button [mat-dialog-close]="true" cdkFocusInitial
					class="btn btn-confirm btn-sm mw-100">Xác nhận</button>
			</div>
		</div>
	</div>
</div>