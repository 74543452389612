import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'
import { Inject } from '@angular/core'
import { saveAs as importedSaveAs } from 'file-saver'
import { ToastrService } from 'ngx-toastr'
import { AppSettings } from '../../constants/app-setting'
import { HttpClient } from '@angular/common/http'
import { Api } from 'src/app/constants/api'
import { UploadFileService } from 'src/app/services/uploadfiles.service'
declare var $: any

@Component({
	selector: 'app-officeview-dialog',
	templateUrl: './officeview-dialog.component.html',
	styleUrls: ['./officeview-dialog.component.css'],
})
export class OfficeViewDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private filesService: UploadFileService,
		private toastr: ToastrService,
		private http: HttpClient,
		private cdRef: ChangeDetectorRef
	) {}
	downloadId: number = 0
	filename: string = ''
	IsShowCreateVersion = false
	files: any
	typeFile: any
	apiAddress: string;
	apiViewFile: string;
	linkViewPPT: string;

	ngOnInit() {
		this.typeFile = this.data.type
		this.apiAddress = this.data.apiaddress;
		this.apiViewFile = this.data.apiviewfile;
		
		if (this.data.link != null) {

			//debugger
			this.data.link = this.data.link.replace("Upload","")
			//this.data.link = this.data.link.substring(6,this.data.link.length)

			this.cdRef.detectChanges()

			let extenfile = this.data.extfile
			if(
				extenfile == 'doc' ||
				extenfile == 'DOC' ||
				extenfile == 'docx' ||
				extenfile == 'DOCX' ||
				extenfile == 'xls' ||
				extenfile == 'XLS' ||
				extenfile == 'xlsx' ||
				extenfile == 'XLSX' ||
				extenfile == 'ppt' ||
				extenfile == 'PPT' ||
				extenfile == 'pptx' ||
				extenfile == 'PPTX' 
			){
				this.linkViewPPT = "https://docs.google.com/gview?url=" + this.apiViewFile + "/" + this.data.link + "&embedded=true";
				//console.log("Link view PPT: " + this.linkViewPPT);
				(document.getElementById('viewppt') as HTMLImageElement).src = this.linkViewPPT;
			} 
			var fileurl = this.data.link
			fileurl = fileurl
			this.filename = fileurl
		}
	}

	DownloadFile() {
		
		var req = {
			filePath: this.data.path
		}
		//var url = this.apiAddress + Api.File_DownloadFile;
		this.filesService.downloadFile(req).subscribe(
			(response) => {
				var blob = new Blob([response], { type: response.type })
				var extenfile = this.data.extfile
				if (
					extenfile == 'doc' ||
					extenfile == 'DOC' ||
					extenfile == 'docx' ||
					extenfile == 'DOCX' ||
					extenfile == 'xls' ||
					extenfile == 'XLS' ||
					extenfile == 'xlsx' ||
					extenfile == 'XLSX' ||
					extenfile == 'ppt' ||
					extenfile == 'PPT' ||
					extenfile == 'pptx' ||
					extenfile == 'PPTX' ||
					extenfile == 'pdf' ||
					extenfile == 'PDF'
				) {
					//importedSaveAs(blob, this.data.name + '.' + this.data.extfile)
					importedSaveAs(blob, this.data.name)

				}
				//
			},
			(error) => {
				this.toastr.error('Không tìm thấy file trên hệ thống')
			}
		)
		
	}
}
