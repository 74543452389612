import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr'
import * as Hammer from 'hammerjs';
declare var $: any

@Component({
  selector: 'app-danh-thiep',
  templateUrl: './danh-thiep.component.html',
  styleUrls: ['./danh-thiep.component.css'],
})

export class DanhThiepComponent implements OnInit {
  
  id: string;
	model: any = {}	
	showPopup: boolean = false;
  constructor(
	private route: ActivatedRoute,
	private userService: UserService,
  private _toastr: ToastrService
	) {}


  elementType1: 'url' | 'canvas' | 'img' = 'url';
	value1: string ="";
	value2: string ="";
  listBank:any = [];

  _tenTaiKhoan: string="";
  _soTaiKhoan: string="";
  _tenNganHang: string="";
  _valueQr : string="";

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.userService.GetInfoDanhThiep({ id: params['id'] }).subscribe((res) => {
		
		if (res.success != 'OK') return
      debugger
			this.model = res.result.data;
      this.listBank = res.result.listBank;
			if(this.model.avatar != null && this.model.avatar != ""){
				this.model.avatar = "https://viewfile.lhhtxocop.vn/SSO/"+ this.model.avatar.replace("Upload","");
			}
		})
    });
  }
  togglePopup() {
    this.showPopup = !this.showPopup;
  }

  showDiaChi()
  {
    if(this.model.address != null && this.model.address != "")
    {
      $('#modalDiaChi').modal('show');
    }
    else
    {
      $('#modalNull').modal('show');
    }
  }

  showZalo()
  {
    if(this.model.zalo != null && this.model.zalo != "")
    {
      window.open(this.model.zalo);
    }
    else
    {
      $('#modalNull').modal('show');
    }
  }

  showFaceBook()
  {
    if(this.model.facebook != null && this.model.facebook != "")
    {
      window.open(this.model.facebook);
    }
    else
    {
      $('#modalNull').modal('show');
    }
  }

  showTikTok()
  {
    if(this.model.tikTok != null && this.model.tikTok != "")
    {
      window.open(this.model.tikTok);
    }
    else
    {
      $('#modalNull').modal('show');
    }
  }

  showInsta()
  {
    if(this.model.instagram != null && this.model.instagram != "")
    {
      window.open(this.model.instagram);
    }
    else
    {
      $('#modalNull').modal('show');
    }
  }

  showWhatsapp()
  {
    if(this.model.whatsApp != null && this.model.whatsApp != "")
    {
      window.open(this.model.whatsApp);
    }
    else
    {
      $('#modalNull').modal('show');

    }
  }

  showSkype() {
    if(this.model.skype != null && this.model.skype != "")
    {
      window.open(this.model.skype);
    }
    else
    {
      $('#modalNull').modal('show');
    }
  }

  showTelegarm() {
    if(this.model.telegram != null && this.model.telegram != "")
    {
      window.open(this.model.telegram);
    }
    else
    {
      $('#modalNull').modal('show');
    }
  }

  showViber() {
    if(this.model.viber != null && this.model.viber != "")
    {
      window.open(this.model.viber);
    }
    else
    {
      $('#modalNull').modal('show');
    }
  }
  slider: number = 0;
  step: number = 0;
  showNganHang()
  {

    if(this.listBank.length != 0)
    {
     $('#modalNganHang').modal('show');
     this.slider = this.listBank.length;
     this.step = 0;
     this._tenTaiKhoan = this.listBank[0].tenTaiKhoan;
     this._soTaiKhoan = this.listBank[0].soTaiKhoan;
     this._tenNganHang = this.listBank[0].tenNganHang;
     this._valueQr = this.listBank[0].crc;

    }
    else
    {
      $('#modalNull').modal('show');
    }
  }
  nextStep(st)
  {
    if(st < this.slider - 1){
      this.step =  st+ 1;
      this._tenTaiKhoan = this.listBank[this.step].tenTaiKhoan;
      this._soTaiKhoan = this.listBank[this.step].soTaiKhoan;
      this._tenNganHang = this.listBank[this.step].tenNganHang;
      this._valueQr = this.listBank[this.step].crc;
    }
   
  }
  backStep(st)
  {
    if(st > 0){
      this.step =  st- 1;
      this._tenTaiKhoan = this.listBank[this.step].tenTaiKhoan;
      this._soTaiKhoan = this.listBank[this.step].soTaiKhoan;
      this._tenNganHang = this.listBank[this.step].tenNganHang;
      this._valueQr = this.listBank[this.step].crc;
    }
   
  }
  copyBank()
  {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this._soTaiKhoan;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toastr.success("Sao chép link thành công !");
  }

  showDanhThiep()
  {
    
   
    this.value1 = location.toString();
    $('#modalDanhThiep').modal('show');
   
  }

  showShare()
  {
    
    $('#modalShare').modal('show');
   
  }

  copyLink()
  {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = location.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toastr.success("Sao chép link thành công !");
  }
  openSMS()
  {
    window.location.href = "sms:?&body=" + location.toString();
  }
  openMessage()
  {
    window.location.href = "fb-messenger://share?link="+ location.toString();
  }
}