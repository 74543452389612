<div class="col-md-12">
  <div class="panel row content-panel" style="min-height: 100vh;margin-bottom: 0!important">
    <div class="error-page text-center" style="margin-top: 30vh">
        <h3><i class="fa fa-warning text-yellow"></i> Xin lỗi! Có giới hạn quyền truy cập.</h3>
        <h4>
          Anh/chị không có quyền với chức năng này.
        </h4>
        <a class="btn btn-md btn-primary" routerLink="../business">Quay lại trang chủ</a>
    </div>
  </div>
</div>
